export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ECourseLessonFileAssetUnion: ['Asset', 'ECourseLessonFileAssetPreview'],
    Actor: ['User', 'Creator'],
    Node: [
      'ECourse',
      'User',
      'LiveStream',
      'ExclusiveContent',
      'ExclusiveContentOrder',
      'ShoutoutOrder',
      'Shoutout',
      'Creator',
      'CreatorLink',
      'TextBlock',
      'OneTimeTip',
      'TipFeedItem',
      'TipSubscription',
      'Goal',
      'TipBlock',
      'CustomBlock',
      'ProductBlock',
      'SocialIconsBlock',
      'MusicPreviewBlock',
      'TwitterEmbedBlock',
      'VideoPreviewBlock',
      'MailingListBlock',
      'PremiumBlock',
      'CarouselBlock',
      'ThumbnailsBlock',
      'SocialFeedBlock',
      'Consultation',
      'ConsultationOrder',
      'Asset',
      'CrmCustomer',
      'ECourseOrder',
      'Invoice',
      'InvoiceOrder',
      'LiveStreamOrder',
      'Membership',
      'MembershipSubscription',
      'MembershipSubscriptionTransaction',
      'RecurringTip',
    ],
    UpdateUserPayload: [
      'UpdateUserPayloadSuccess',
      'InputValidationError',
      'EmailTakenError',
      'UsernameTakenError',
    ],
    VerifyCreatorEmailPayload: [
      'VerifyCreatorEmailPayloadSuccess',
      'NotFoundError',
    ],
    UpdateCreatorPayload: [
      'UpdateCreatorPayloadSuccess',
      'InputValidationError',
      'EmailTakenError',
      'UsernameTakenError',
      'NotFoundError',
    ],
    UpdateCreatorReferredByPayload: [
      'UpdateCreatorReferredByPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DeleteCreatorReferredByPayload: [
      'DeleteCreatorReferredByPayloadSuccess',
      'NotFoundError',
    ],
    ConnectStripePayload: [
      'ConnectStripePayloadSuccess',
      'StripeClientRequestError',
    ],
    ConnectPaypalPayload: [
      'ConnectPaypalPayloadSuccess',
      'PaypalError',
      'PaypalAccountReadinessError',
    ],
    DisconnectGooglePayload: [
      'DisconnectGooglePayloadSuccess',
      'NotFoundError',
    ],
    DisconnectZoomPayload: ['DisconnectZoomPayloadSuccess', 'NotFoundError'],
    DisconnectMailchimpPayload: [
      'DisconnectMailchimpPayloadSuccess',
      'NotFoundError',
    ],
    ConnectMailchimpPayload: [
      'ConnectMailchimpPayloadSuccess',
      'ExternalServiceRequestError',
    ],
    ConnectGooglePayload: [
      'ConnectGooglePayloadSuccess',
      'ExternalServiceRequestError',
    ],
    ConnectZoomPayload: [
      'ConnectZoomPayloadSuccess',
      'ExternalServiceRequestError',
    ],
    UpdateCreatorApplicationFeePayload: [
      'UpdateCreatorApplicationFeePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateCreatorUsernamePayload: [
      'UpdateCreatorUsernamePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'UsernameTakenError',
    ],
    GenerateHubspotChatTokenPayload: [
      'GenerateHubspotChatTokenPayloadSuccess',
      'ExternalServiceRequestError',
    ],
    AddCreatorCategoriesPayload: [
      'AddCreatorCategoriesPayloadSuccess',
      'InputValidationError',
    ],
    UpdateCreatorCategoriesPayload: [
      'UpdateCreatorCategoriesPayloadSuccess',
      'InputValidationError',
    ],
    UpdateCreatorOnboardingStepPayload: [
      'UpdateCreatorOnboardingStepPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    LinkBlock: [
      'TextBlock',
      'TipBlock',
      'CustomBlock',
      'ProductBlock',
      'SocialIconsBlock',
      'MusicPreviewBlock',
      'TwitterEmbedBlock',
      'VideoPreviewBlock',
      'MailingListBlock',
      'PremiumBlock',
      'CarouselBlock',
      'ThumbnailsBlock',
      'SocialFeedBlock',
    ],
    LinkBackgroundStyle: [
      'LinkPhotoBackground',
      'LinkCoverBackground',
      'LinkSolidBackground',
      'LinkGradientBackground',
    ],
    ISubscription: ['TipSubscription'],
    PremiumLinkCard: [
      'BundleLinkCard',
      'ConsultationLinkCard',
      'MembershipLinkCard',
      'ShoutoutLinkCard',
      'LiveStreamLinkCard',
      'ExclusiveContentLinkCard',
      'ECourseLinkCard',
    ],
    CarouselBlockItem: [
      'BundleLinkCard',
      'ShoutoutLinkCard',
      'LiveStreamLinkCard',
      'ProductBlock',
      'ConsultationLinkCard',
      'ExclusiveContentLinkCard',
      'ECourseLinkCard',
      'MembershipLinkCard',
    ],
    LinkBlockItem: [
      'CustomBlock',
      'TipBlock',
      'SocialIconsBlock',
      'PremiumBlock',
      'VideoPreviewBlock',
      'MusicPreviewBlock',
      'TwitterEmbedBlock',
      'MailingListBlock',
      'ProductBlock',
      'TextBlock',
      'CarouselBlock',
      'ThumbnailsBlock',
      'SocialFeedBlock',
    ],
    UpsertSocialLinkPayload: [
      'UpsertSocialLinkPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateVipStatusPayload: ['UpdateVipStatusPayloadSuccess', 'NotFoundError'],
    AddEmailToMailingListPayload: [
      'AddEmailToMailingListPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateLinkProfilePayload: [
      'UpdateLinkProfilePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    AddTextBlockPayload: [
      'AddTextBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateTextBlockPayload: [
      'UpdateTextBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'AssetNotLinkableToResourceError',
    ],
    AddCustomBlockPayload: [
      'AddCustomBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateCustomBlockPayload: [
      'UpdateCustomBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddProductBlockPayload: [
      'AddProductBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateProductBlockPayload: [
      'UpdateProductBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddCoverAssetToProductBlockPayload: [
      'AddCoverAssetToProductBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'AssetNotLinkableToResourceError',
    ],
    DeleteCoverAssetFromProductBlockPayload: [
      'DeleteCoverAssetFromProductBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateLinkBlockVisibilityPayload: [
      'UpdateLinkBlockVisibilityPayloadSuccess',
      'NotFoundError',
    ],
    UpdatePremiumBlockPayload: [
      'UpdatePremiumBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateCarouselBlockPayload: [
      'UpdateCarouselBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddItemToCarouselBlockPayload: [
      'AddItemToCarouselBlockPayloadSuccess',
      'NotFoundError',
    ],
    UpsertSocialIconPayload: [
      'UpsertSocialIconPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DeleteSocialIconPayload: [
      'DeleteSocialIconPayloadSuccess',
      'NotFoundError',
    ],
    SortBlocksPayload: [
      'SortBlocksPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    SortBlockCardsPayload: [
      'SortBlockCardsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateMailingListBlockPayload: [
      'UpdateMailingListBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    SortSocialIconsPayload: [
      'SortSocialIconsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateVideoBlockPayload: [
      'UpdateVideoBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddMusicBlockPayload: [
      'AddMusicBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddTwitterEmbedBlockPayload: [
      'AddTwitterEmbedBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddVideoBlockPayload: [
      'AddVideoBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddPremiumBlockPayload: [
      'AddPremiumBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddTipBlockPayload: [
      'AddTipBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddMailingListBlockPayload: [
      'AddMailingListBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddCarouselBlockPayload: [
      'AddCarouselBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddSocialIconsBlockPayload: [
      'AddSocialIconsBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateMusicBlockPayload: [
      'UpdateMusicBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateTwitterEmbedBlockPayload: [
      'UpdateTwitterEmbedBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateLinkBackgroundPayload: [
      'UpdateLinkBackgroundPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateLinkColorsPayload: [
      'UpdateLinkColorsPayloadSuccess',
      'NotFoundError',
    ],
    UpdateLinkFontsPayload: ['UpdateLinkFontsPayloadSuccess', 'NotFoundError'],
    AssignLinkCustomDomainNamePayload: [
      'AssignLinkCustomDomainNamePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UnassignLinkCustomDomainNamePayload: [
      'UnassignLinkCustomDomainNamePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    CheckLinkCustomDomainNameAvailabilityPayload: [
      'CheckLinkCustomDomainNameAvailabilityPayloadSuccess',
      'InputValidationError',
    ],
    BuyLinkCustomDomainNamePayload: [
      'BuyLinkCustomDomainNamePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateLinkBlockStylesPayload: [
      'UpdateLinkBlockStylesPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateLinkStylesPayload: [
      'UpdateLinkStylesPayloadSuccess',
      'NotFoundError',
    ],
    Transaction: [
      'ExclusiveContentOrder',
      'ShoutoutOrder',
      'OneTimeTip',
      'ConsultationOrder',
      'ECourseOrder',
      'InvoiceOrder',
      'LiveStreamOrder',
      'MembershipSubscriptionTransaction',
      'RecurringTip',
    ],
    SigninAsCreatorPayload: [
      'SigninAsCreatorPayloadSuccess',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    SigninBySingleUseTokenPayload: [
      'SigninBySingleUseTokenPayloadSuccess',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    CompleteUserProfilePayload: [
      'CompleteUserProfilePayloadSuccess',
      'NotFoundError',
      'UsernameTakenError',
      'CaptchaVerificationFailedError',
      'InputValidationError',
    ],
    SignupCreatorPayload: [
      'SignupCreatorPayloadSuccess',
      'InputValidationError',
      'EmailTakenError',
      'UsernameTakenError',
      'CaptchaVerificationFailedError',
    ],
    SigninCreatorPayload: [
      'SigninCreatorPayloadSuccess',
      'InputValidationError',
      'WrongCredentialsError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    RequestPasswordResetPayload: [
      'RequestPasswordResetPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    VerifyEmailPayload: [
      'VerifyEmailPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
      'WrongCredentialsError',
    ],
    RequestEmailVerificationPayload: [
      'RequestEmailVerificationPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    ResetPasswordPayload: [
      'ResetPasswordPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    UpdatePasswordPayload: [
      'UpdatePasswordPayloadSuccess',
      'InputValidationError',
      'WrongCredentialsError',
      'CaptchaVerificationFailedError',
    ],
    UserError: [
      'InputValidationError',
      'CaptchaVerificationFailedError',
      'EmailTakenError',
      'UsernameTakenError',
      'WrongCredentialsError',
      'UniqueConstraintViolationError',
      'NotFoundError',
      'PartialCouponDiscountError',
      'NotDeletableError',
      'UnpublishableContentError',
      'UpdateCollectionContentError',
      'StripeClientRequestError',
      'PaypalClientRequestError',
      'InvalidImportContentUrlError',
      'CreatorUnavailableError',
      'UpdateOrderStatusError',
      'CreatorNoConnectedPaymentError',
      'PaymentDeclinedError',
      'BundleAlreadyPurchasedError',
      'ExclusiveContentAlreadyPurchasedError',
      'LiveStreamAlreadyPurchasedError',
      'LiveStreamAlreadyFullError',
      'LiveStreamAlreadyHappenedError',
      'LiveStreamIsMonetizedError',
      'LiveStreamIsNotMonetizedError',
      'LiveStreamHasRegisteredUsersError',
      'ExistingSubscriptionError',
      'UnsupportedPlatformError',
      'ExternalServiceRequestError',
      'ShoutoutIsMonetizedError',
      'ImportContentLinkBeingProcessedError',
      'ImportContentLinkAlreadyProcessedError',
      'ActorAlreadyDisabledError',
      'ActorAlreadyEnabledError',
      'ActorDisabledError',
      'InvalidConsultationAvailabilitiesError',
      'ConsultationAlreadyPurchasedError',
      'ConsultationUnavailableError',
      'ConsultationIsMonetizedError',
      'ConsultationIsNotMonetizedError',
      'AssetNotLinkableToResourceError',
      'ExclusiveContentIsMonetizedError',
      'ExclusiveContentIsNotMonetizedError',
      'PaypalAccountInUseError',
      'StripeAccountInUseError',
      'ECourseAlreadyPurchasedError',
      'ECourseIsMonetizedError',
      'CreatorNotEligibleForTrialError',
      'DuplicatePolicyKindError',
    ],
    CreateImportContentLinkRequestPayload: [
      'CreateImportContentLinkRequestPayloadSuccess',
      'UnsupportedPlatformError',
      'InputValidationError',
      'UniqueConstraintViolationError',
      'InvalidImportContentUrlError',
      'NotFoundError',
      'ImportContentLinkBeingProcessedError',
      'ImportContentLinkAlreadyProcessedError',
    ],
    AuthorizeApprovedPaypalOrderPayload: [
      'AuthorizeApprovedPaypalOrderPayloadSuccess',
      'PaypalClientRequestError',
      'NotFoundError',
    ],
    ReauthorizeApprovedPaypalOrderPayload: [
      'ReauthorizeApprovedPaypalOrderPayloadSuccess',
      'PaypalClientRequestError',
      'NotFoundError',
    ],
    AddStripeIntentPayload: [
      'AddStripeIntentPayloadSuccess',
      'CreatorUnavailableError',
      'InputValidationError',
      'StripeClientRequestError',
      'CreatorNoConnectedPaymentError',
    ],
    RefundTransactionPayload: [
      'RefundTransactionPayloadSuccess',
      'InputValidationError',
      'StripeClientRequestError',
      'PaypalClientRequestError',
      'NotFoundError',
    ],
    CaptureApprovedPaypalOrderPayload: [
      'CaptureApprovedPaypalOrderPayloadSuccess',
      'PaypalClientRequestError',
      'NotFoundError',
      'PaymentDeclinedError',
    ],
    DisableActorPayload: [
      'DisableActorPayloadSuccess',
      'NotFoundError',
      'ActorAlreadyDisabledError',
      'InputValidationError',
    ],
    EnableActorPayload: [
      'EnableActorPayloadSuccess',
      'NotFoundError',
      'ActorAlreadyEnabledError',
    ],
    ConsultationScheduleRange: [
      'ConsultationScheduleRangeFutureDay',
      'ConsultationScheduleRangeFutureIndefinitely',
      'ConsultationScheduleRangeFutureRange',
    ],
    ConsultationScheduleRangeItem: [
      'ConsultationScheduleRangeFutureDay',
      'ConsultationScheduleRangeFutureIndefinitely',
      'ConsultationScheduleRangeFutureRange',
    ],
    ConsultationAvailability: [
      'ConsultationAvailabilityDay',
      'ConsultationAvailabilityDate',
    ],
    CreateAssetPayload: ['CreateAssetPayloadSuccess', 'InputValidationError'],
    DeleteAssetPayload: [
      'DeleteAssetPayloadSuccess',
      'NotDeletableError',
      'NotFoundError',
    ],
    File: ['ImageField'],
    SaleDiscountResource: [
      'Consultation',
      'ExclusiveContent',
      'LiveStream',
      'Shoutout',
      'ECourse',
    ],
    ApplySaleDiscountPayload: [
      'ApplySaleDiscountPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UnapplySaleDiscountPayload: [
      'UnapplySaleDiscountPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    ExportDataRequestPayload: [
      'ExportDataRequestPayloadSuccess',
      'ExternalServiceRequestError',
      'NotFoundError',
    ],
    UpdateCreatorSelectedGoogleCalendarsPayload: [
      'UpdateCreatorSelectedGoogleCalendarsPayloadSuccess',
      'ExternalServiceRequestError',
      'NotFoundError',
    ],
    FindOrCreatePlanolyUserPayload: [
      'FindOrCreatePlanolyUserPayloadSuccess',
      'AccountAvailableForLinkingPayload',
      'NotFoundError',
      'ActorDisabledError',
      'CaptchaVerificationFailedError',
    ],
    UnlinkCreatorAccountFromPlanolyPayload: [
      'UnlinkCreatorAccountFromPlanolyPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'EmailTakenError',
    ],
    ZapierOutputField: [
      'ZapierMailingListOutputField',
      'ZapierConsultationOrderOutputField',
      'ZapierTransactionConfirmedOutputField',
      'ZapierLivestreamOrderOutputField',
      'ZapierShoutoutOrderOutputField',
      'ZapierExclusiveContentOrderOutputField',
      'ZapierOneTimeTipReceivedOutputField',
      'ZapierRecurringTipReceivedOutputField',
      'ZapierTransactionOutputField',
      'ZapierECourseOrderOutputField',
      'ZapierInvoiceOrderOutputField',
    ],
    GetZapierTriggerSamplesPayload: [
      'GetZapierTriggerSamplesPayloadSuccess',
      'InputValidationError',
    ],
    UpdateZapierWebhookSubscriptionPayload: [
      'UpdateZapierWebhookSubscriptionPayloadSuccess',
      'InputValidationError',
    ],
    AddItemToSocialFeedBlockFromPlanolyPayload: [
      'AddItemToSocialFeedBlockFromPlanolyPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DeleteItemFromCarouselBlockPayload: [
      'DeleteItemFromCarouselBlockPayloadSuccess',
      'NotFoundError',
    ],
    DeleteLinkBlockPayload: [
      'DeleteLinkBlockPayloadSuccess',
      'NotDeletableError',
      'NotFoundError',
    ],
    DeleteSocialFeedBlockItemPayload: [
      'DeleteSocialFeedBlockItemPayloadSuccess',
      'NotFoundError',
    ],
    GetSocialFeedBlocksByPlanolyCreatorPayload: [
      'GetSocialFeedBlocksByPlanolyCreatorPayloadSuccess',
      'NotFoundError',
    ],
    ReassignPurchasedLinkCustomDomainNamePayload: [
      'ReassignPurchasedLinkCustomDomainNamePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    SetPremiumTemplatePayload: [
      'SetPremiumTemplatePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    SortSocialFeedBlockItemsPayload: [
      'SortSocialFeedBlockItemsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UnsetPremiumTemplatePayload: [
      'UnsetPremiumTemplatePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpsertSocialIconsPayload: [
      'UpsertSocialIconsPayloadSuccess',
      'NotFoundError',
    ],
    AddConsultationPayload: [
      'AddConsultationPayloadSuccess',
      'InputValidationError',
    ],
    AddConsultationOrderPayload: [
      'AddConsultationOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorNoConnectedPaymentError',
      'StripeClientRequestError',
      'CreatorUnavailableError',
      'ConsultationUnavailableError',
    ],
    AddFreeConsultationOrderPayload: [
      'AddFreeConsultationOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorUnavailableError',
      'ConsultationUnavailableError',
      'ConsultationIsMonetizedError',
    ],
    AddPaypalConsultationOrderPayload: [
      'AddPaypalConsultationOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorNoConnectedPaymentError',
      'CreatorUnavailableError',
      'ConsultationUnavailableError',
      'PaypalClientRequestError',
    ],
    CancelConsultationOrderPayload: [
      'CancelConsultationOrderPayloadSuccess',
      'NotFoundError',
      'UpdateOrderStatusError',
      'StripeClientRequestError',
      'PaypalClientRequestError',
    ],
    DeleteConsultationPayload: [
      'DeleteConsultationPayloadSuccess',
      'DeleteConsultationPayloadUnpublished',
      'NotFoundError',
    ],
    PublishConsultationPayload: [
      'PublishConsultationPayloadSuccess',
      'UnpublishableContentError',
      'NotFoundError',
    ],
    RescheduleConsultationOrderPayload: [
      'RescheduleConsultationOrderPayloadSuccess',
      'NotFoundError',
      'ConsultationUnavailableError',
    ],
    UnpublishConsultationPayload: [
      'UnpublishConsultationPayloadSuccess',
      'NotFoundError',
    ],
    UpdateConsultationPayload: [
      'UpdateConsultationPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateConsultationAvailabilitiesPayload: [
      'UpdateConsultationAvailabilitiesPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateCustomConsultationQuestionsPayload: [
      'UpdateCustomConsultationQuestionsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddExclusiveContentPayload: [
      'AddExclusiveContentPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddExclusiveContentFileBlockPayload: [
      'AddExclusiveContentFileBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'AssetNotLinkableToResourceError',
    ],
    AddExclusiveContentTextBlockPayload: [
      'AddExclusiveContentTextBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddFreeExclusiveContentOrderPayload: [
      'AddFreeExclusiveContentOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'ExclusiveContentAlreadyPurchasedError',
      'ExclusiveContentIsMonetizedError',
      'PartialCouponDiscountError',
    ],
    DeleteExclusiveContentPayload: [
      'DeleteExclusiveContentPayloadSuccess',
      'DeleteExclusiveContentPayloadUnpublished',
      'NotFoundError',
    ],
    DeleteExclusiveContentBlockPayload: [
      'DeleteExclusiveContentBlockPayloadSuccess',
      'NotFoundError',
    ],
    PublishExclusiveContentPayload: [
      'PublishExclusiveContentPayloadSuccess',
      'UnpublishableContentError',
      'NotFoundError',
    ],
    SortExclusiveContentBlocksPayload: [
      'SortExclusiveContentBlocksPayloadSuccess',
      'NotFoundError',
    ],
    ExclusiveContentBlockItem: [
      'ExclusiveContentFileBlock',
      'ExclusiveContentTitleBlock',
      'ExclusiveContentTextBlock',
    ],
    UnpublishExclusiveContentPayload: [
      'UnpublishExclusiveContentPayloadSuccess',
      'NotFoundError',
    ],
    UpdateExclusiveContentPayload: [
      'UpdateExclusiveContentPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateExclusiveContentBlockVisibilityPayload: [
      'UpdateExclusiveContentBlockVisibilityPayloadSuccess',
      'NotFoundError',
    ],
    UpdateExclusiveContentFileBlockPayload: [
      'UpdateExclusiveContentFileBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateExclusiveContentBlockMonetizationPayload: [
      'UpdateExclusiveContentBlockMonetizationPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateExclusiveContentTextBlockPayload: [
      'UpdateExclusiveContentTextBlockPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddLiveStreamPayload: [
      'AddLiveStreamPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    DeleteLiveStreamPayload: [
      'DeleteLiveStreamPayloadSuccess',
      'DeleteLiveStreamPayloadUnpublished',
      'NotFoundError',
    ],
    PublishLiveStreamPayload: [
      'PublishLiveStreamPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ExternalServiceRequestError',
      'UnpublishableContentError',
    ],
    UnpublishLiveStreamPayload: [
      'UnpublishLiveStreamPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ExternalServiceRequestError',
    ],
    UpdateLiveStreamPayload: [
      'UpdateLiveStreamPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    AddMembershipPayload: [
      'AddMembershipPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    DeleteMembershipPayload: [
      'DeleteMembershipPayloadSuccess',
      'InputValidationError',
      'NotDeletableError',
      'NotFoundError',
    ],
    MembershipBlockItem: [],
    MembershipBlock: ['MembershipFileBlock', 'MembershipTextBlock'],
    UpdateMembershipPayload: [
      'UpdateMembershipPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    GenerateMagicLinkToPurchasePayload: [
      'GenerateMagicLinkToPurchasePayloadSuccess',
      'NotFoundError',
    ],
    RequestMagicLinkToPurchasePayload: [
      'RequestMagicLinkToPurchasePayloadSuccess',
      'NotFoundError',
      'ActorDisabledError',
    ],
    CouponDiscountable: [
      'ExclusiveContent',
      'Consultation',
      'LiveStream',
      'Shoutout',
      'ECourse',
    ],
    BaseSubscription: ['MembershipSubscription'],
    ResourceType: [
      'ExclusiveContent',
      'Consultation',
      'LiveStream',
      'Shoutout',
      'ECourse',
      'Membership',
    ],
    AddReceiptEmailCustomMessagePayload: [
      'AddReceiptEmailCustomMessagePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    ShortLinkableService: [
      'ExclusiveContent',
      'Consultation',
      'LiveStream',
      'Shoutout',
      'ECourse',
      'Invoice',
      'Membership',
    ],
    AddResourceShortUrlPayload: [
      'AddResourceShortUrlPayloadSuccess',
      'InputValidationError',
    ],
    AddShortUrlPayload: ['AddShortUrlPayloadSuccess', 'InputValidationError'],
    DeleteShortUrlPayload: ['DeleteShortUrlPayloadSuccess', 'NotFoundError'],
    UpdateShortUrlPayload: [
      'UpdateShortUrlPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AcceptShoutoutOrderPayload: [
      'AcceptShoutoutOrderPayloadSuccess',
      'NotFoundError',
      'UpdateOrderStatusError',
    ],
    AddFreeShoutoutOrderPayload: [
      'AddFreeShoutoutOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorUnavailableError',
      'ShoutoutIsMonetizedError',
      'PartialCouponDiscountError',
    ],
    AddPaypalShoutoutOrderPayload: [
      'AddPaypalShoutoutOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorUnavailableError',
      'PaypalClientRequestError',
      'CreatorNoConnectedPaymentError',
    ],
    AddShoutoutPayload: ['AddShoutoutPayloadSuccess', 'InputValidationError'],
    AddShoutoutOrderPayload: [
      'AddShoutoutOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorUnavailableError',
      'StripeClientRequestError',
      'CreatorNoConnectedPaymentError',
    ],
    DeleteShoutoutPayload: [
      'DeleteShoutoutPayloadSuccess',
      'DeleteShoutoutPayloadUnpublished',
      'NotFoundError',
    ],
    FulfillShoutoutOrderPayload: [
      'FulfillShoutoutOrderPayloadSuccess',
      'NotFoundError',
      'UpdateOrderStatusError',
      'PaypalClientRequestError',
      'StripeClientRequestError',
      'AssetNotLinkableToResourceError',
    ],
    PublishShoutoutPayload: [
      'PublishShoutoutPayloadSuccess',
      'UnpublishableContentError',
      'NotFoundError',
    ],
    RejectShoutoutOrderPayload: [
      'RejectShoutoutOrderPayloadSuccess',
      'NotFoundError',
      'UpdateOrderStatusError',
      'PaypalClientRequestError',
      'StripeClientRequestError',
    ],
    UnpublishShoutoutPayload: [
      'UnpublishShoutoutPayloadSuccess',
      'NotFoundError',
    ],
    UpdateCustomShoutoutQuestionsPayload: [
      'UpdateCustomShoutoutQuestionsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateShoutoutPayload: [
      'UpdateShoutoutPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    AddPaypalTipOrderPayload: [
      'AddPaypalTipOrderPayloadSuccess',
      'InputValidationError',
      'PaypalClientRequestError',
      'CreatorNoConnectedPaymentError',
      'NotFoundError',
    ],
    AddTipPayload: [
      'AddTipPayloadSuccess',
      'InputValidationError',
      'StripeClientRequestError',
      'CreatorNoConnectedPaymentError',
      'NotFoundError',
    ],
    AddTipBlockGoalPayload: [
      'AddTipBlockGoalPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddTipStripeSubscriptionPayload: [
      'AddTipStripeSubscriptionPayloadSuccess',
      'StripeClientRequestError',
      'ExistingSubscriptionError',
      'CreatorNoConnectedPaymentError',
      'NotFoundError',
    ],
    CancelSubscriptionPayload: [
      'CancelSubscriptionPayloadSuccess',
      'StripeClientRequestError',
      'NotFoundError',
    ],
    DeleteTipBlockGoalPayload: [
      'DeleteTipBlockGoalPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    ResetTipBlockGoalPayload: [
      'ResetTipBlockGoalPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    RetryStripeInvoicePaymentPayload: [
      'RetryStripeInvoicePaymentPayloadSuccess',
      'StripeClientRequestError',
      'NotFoundError',
    ],
    UpdateTipBlockPayload: [
      'UpdateTipBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateTipBlockGoalPayload: [
      'UpdateTipBlockGoalPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    BulkUpsertCreatorPoliciesPayload: [
      'BulkUpsertCreatorPoliciesPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'DuplicatePolicyKindError',
    ],
    DeleteCreatorAccountPayload: [
      'DeleteCreatorAccountPayloadSuccess',
      'NotFoundError',
    ],
    DeleteCreatorPlanolyAccountPayload: [
      'DeleteCreatorPlanolyAccountPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DeleteCreatorPlanolyWorkspacePayload: [
      'DeleteCreatorPlanolyWorkspacePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DisconnectPaypalPayload: [
      'NotFoundError',
      'PaypalAccountInUseError',
      'DisconnectPaypalPayloadSuccess',
    ],
    DisconnectStripePayload: [
      'NotFoundError',
      'StripeAccountInUseError',
      'StripeClientRequestError',
      'DisconnectStripePayloadSuccess',
    ],
    DeleteUserAccountPayload: [
      'DeleteUserAccountPayloadSuccess',
      'NotFoundError',
    ],
    CustomQuestionService: [
      'ExclusiveContent',
      'Consultation',
      'LiveStream',
      'Shoutout',
      'ECourse',
      'Membership',
    ],
    UpdateServiceCustomQuestionsPayload: [
      'UpdateServiceCustomQuestionsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    RatingInterface: [
      'ECourse',
      'LiveStream',
      'ExclusiveContent',
      'Shoutout',
      'Consultation',
      'Membership',
    ],
    OrderRatingInterface: [
      'ExclusiveContentOrder',
      'ShoutoutOrder',
      'ConsultationOrder',
      'ECourseOrder',
      'LiveStreamOrder',
      'MembershipSubscription',
    ],
    RateableService: [
      'ExclusiveContent',
      'Consultation',
      'LiveStream',
      'Shoutout',
      'ECourse',
      'Membership',
    ],
    UpdateServiceRatingConfigurationPayload: [
      'UpdateServiceRatingConfigurationPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddItemToSocialFeedBlockPayload: [
      'AddItemToSocialFeedBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    AddSocialFeedBlockPayload: [
      'AddSocialFeedBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddThumbnailsBlockPayload: [
      'AddThumbnailsBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateSocialFeedBlockPayload: [
      'UpdateSocialFeedBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateSocialFeedBlockItemPayload: [
      'UpdateSocialFeedBlockItemPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'AssetNotLinkableToResourceError',
    ],
    UpdateThumbnailsBlockPayload: [
      'UpdateThumbnailsBlockPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddECoursePayload: [
      'AddECoursePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddECourseLessonPayload: [
      'AddECourseLessonPayloadSuccess',
      'InputValidationError',
    ],
    AddECourseLessonFilePayload: [
      'AddECourseLessonFilePayloadSuccess',
      'InputValidationError',
    ],
    AddECourseOrderPayload: [
      'AddECourseOrderPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ECourseAlreadyPurchasedError',
      'CreatorNoConnectedPaymentError',
    ],
    AddECourseTrailerPayload: [
      'AddECourseTrailerPayloadSuccess',
      'InputValidationError',
    ],
    AddFreeECourseOrderPayload: [
      'AddFreeECourseOrderPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ECourseIsMonetizedError',
      'ECourseAlreadyPurchasedError',
    ],
    AddPaypalECourseOrderPayload: [
      'AddPaypalECourseOrderPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'ECourseAlreadyPurchasedError',
      'CreatorNoConnectedPaymentError',
      'PaypalClientRequestError',
    ],
    DeleteECoursePayload: [
      'DeleteECoursePayloadSuccess',
      'NotDeletableError',
      'NotFoundError',
    ],
    DeleteECourseLessonPayload: [
      'DeleteECourseLessonPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DeleteECourseLessonFilePayload: [
      'DeleteECourseLessonFilePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    DeleteECourseTrailerPayload: [
      'DeleteECourseTrailerPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    MarkECourseLessonAsVisibleOrInvisiblePayload: [
      'MarkECourseLessonAsVisibleOrInvisiblePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    MarkECourseLessonFileAsCompletedOrUncompletedPayload: [
      'MarkECourseLessonFileAsCompletedOrUncompletedPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    MarkECourseLessonFileAsVisibleOrInvisiblePayload: [
      'MarkECourseLessonFileAsVisibleOrInvisiblePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    PublishECoursePayload: [
      'PublishECoursePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    SortECourseLessonFilesPayload: [
      'SortECourseLessonFilesPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    SortECourseLessonsPayload: [
      'SortECourseLessonsPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    SortECourseTrailersPayload: [
      'SortECourseTrailersPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UnpublishECoursePayload: [
      'UnpublishECoursePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateECoursePayload: [
      'UpdateECoursePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateECourseLessonPayload: [
      'UpdateECourseLessonPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateECourseLessonFilePayload: [
      'UpdateECourseLessonFilePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    UpdateECourseTrailerPayload: [
      'UpdateECourseTrailerPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddExclusiveContentOrderPayload: [
      'AddExclusiveContentOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorNoConnectedPaymentError',
      'ExclusiveContentAlreadyPurchasedError',
      'StripeClientRequestError',
      'CreatorUnavailableError',
    ],
    AddPaypalExclusiveContentOrderPayload: [
      'AddPaypalExclusiveContentOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorUnavailableError',
      'PaypalClientRequestError',
      'CreatorNoConnectedPaymentError',
      'ExclusiveContentAlreadyPurchasedError',
    ],
    Add30DayTrialWithoutPaymentMethodPayload: [
      'Add30DayTrialWithoutPaymentMethodPayloadSuccess',
      'ExternalServiceRequestError',
      'NotFoundError',
      'CreatorNotEligibleForTrialError',
    ],
    AddFreeInvoiceOrderPayload: [
      'AddFreeInvoiceOrderPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddInvoicePayload: [
      'AddInvoicePayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    AddInvoiceOrderPayload: [
      'AddInvoiceOrderPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'CreatorNoConnectedPaymentError',
    ],
    AddPaypalInvoiceOrderPayload: [
      'AddPaypalInvoiceOrderPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'CreatorNoConnectedPaymentError',
      'PaypalClientRequestError',
    ],
    DeleteInvoicePayload: [
      'DeleteInvoicePayloadSuccess',
      'NotDeletableError',
      'NotFoundError',
    ],
    InvoiceByNumberPayload: ['InvoiceByNumberPayloadSuccess', 'NotFoundError'],
    PublishInvoicePayload: [
      'PublishInvoicePayloadSuccess',
      'UnpublishableContentError',
      'NotFoundError',
      'InputValidationError',
    ],
    SendInvoiceViaEmailPayload: [
      'SendInvoiceViaEmailPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateInvoicePayload: [
      'UpdateInvoicePayloadSuccess',
      'NotFoundError',
      'InputValidationError',
    ],
    AddFreeLiveStreamOrderPayload: [
      'AddFreeLiveStreamOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'LiveStreamAlreadyPurchasedError',
      'LiveStreamAlreadyHappenedError',
      'LiveStreamAlreadyFullError',
      'LiveStreamIsMonetizedError',
    ],
    AddLiveStreamOrderPayload: [
      'AddLiveStreamOrderPayloadSuccess',
      'CreatorUnavailableError',
      'NotFoundError',
      'InputValidationError',
      'CreatorNoConnectedPaymentError',
      'StripeClientRequestError',
      'LiveStreamAlreadyHappenedError',
      'LiveStreamAlreadyFullError',
      'LiveStreamAlreadyPurchasedError',
    ],
    AddPaypalLiveStreamOrderPayload: [
      'AddPaypalLiveStreamOrderPayloadSuccess',
      'NotFoundError',
      'InputValidationError',
      'CreatorNoConnectedPaymentError',
      'LiveStreamAlreadyPurchasedError',
      'LiveStreamAlreadyHappenedError',
      'LiveStreamAlreadyFullError',
    ],
    AddMembershipSubscriptionPayload: [
      'AddMembershipSubscriptionPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'CreatorNoConnectedPaymentError',
    ],
    UpdateMembershipSubscriptionPayload: [
      'UpdateMembershipSubscriptionPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'CreatorNoConnectedPaymentError',
    ],
    CalculateDiscountsPayload: [
      'CalculateDiscountsPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
      'InvalidDeductiblePriceError',
    ],
    AddDiscountCouponPayload: [
      'AddDiscountCouponPayloadSuccess',
      'InputValidationError',
    ],
    DeleteDiscountCouponPayload: [
      'DeleteDiscountCouponPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpdateDiscountCouponPayload: [
      'UpdateDiscountCouponPayloadSuccess',
      'InputValidationError',
    ],
    UpdateDiscountCouponStatusPayload: [
      'UpdateDiscountCouponStatusSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    GeneratePaypalOnboardingLinkPayload: [
      'GeneratePaypalOnboardingLinkPayloadSuccess',
      'PaypalError',
      'ExternalServiceRequestError',
      'NotFoundError',
    ],
    SetInvoicesPreferences: ['SetInvoicesPreferencesSuccess', 'NotFoundError'],
    SetPaypalPreferences: ['SetPaypalPreferencesSuccess', 'NotFoundError'],
    CancelBaseSubscriptionPayload: [
      'CancelBaseSubscriptionPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    TransactionNode: [
      'InvoiceOrder',
      'ExclusiveContentOrder',
      'LiveStreamOrder',
      'ShoutoutOrder',
      'ConsultationOrder',
      'ECourseOrder',
      'OneTimeTip',
      'RecurringTip',
      'MembershipSubscriptionTransaction',
    ],
    UpsertRatingPayload: [
      'UpsertRatingPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
    UpsertTransactionRatingPayload: [
      'UpsertTransactionRatingPayloadSuccess',
      'InputValidationError',
      'NotFoundError',
    ],
  },
}
export default result
